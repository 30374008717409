export default {
  data () {
    return {
      kinds: {
        'Residential': 'Lokal mieszkalny',
        'Plot': 'Grunt niezabudowany',
        'OnlyParkingSpace': 'Miejsce postojowe',
        'Garage': 'Garaż',
        'House': 'Budynek mieszkalny jednorodzinny/ segment/ bliźniak a także lokal mieszkalny w budynku w zabudowie szeregowej lub bliźniaczej'
      }
    }
  },
  methods: {
    getKindLabel (kind) {
      if (kind === 'House') {
        return 'Budynek mieszkalny jednorodzinny'
      }
      return this.kinds.hasOwnProperty(kind) ? this.kinds[kind] : kind
    }
  }
}
