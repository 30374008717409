<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :task-id="taskId"
      :task="task"
      :open="show"
      :assignee-uuid="assigneeUuid"
      @multiselectInput="handleChangeUser"
      :service="service"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
import FormModal from './form/ChangeAssignee.vue'
import api from '../../../../../api'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    taskId: { required: true },
    assigneeUuid: { type: String, required: true, default: '' },
    task: { type: Object, required: true, default: () => {} }
  },
  data () {
    return {
      title: 'Zmiana opiekuna zlecenia',
      okText: 'Zmień opiekuna'
    }
  },
  methods: {
    handleChangeUser (event) {
      let services = ['extgen', 'extpko', 'extavi', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp']
      if (services.indexOf(this.service) > -1) {
        this.model = event
      } else {
        this.model.selectedUserUuid = event
      }
    },
    submitForm () {
      let services = ['extgen', 'extpko', 'extavi', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp']
      if (services.indexOf(this.service) > -1) {
        try {
          let dataToSend = {
            newBusinessHour: {
              id: this.model.businessHourId,
              startAt: this.model.startAt,
              endAt: this.model.endAt
            }
          }
          api.request(this.service, 'put', `/booking-dates/${this.model.bookingDateToChange}/edit`, dataToSend)
            .then((response) => {
              this.$events.emit('task:changedState')
            }).catch((error) => {
              this.errorNotify(error)
            })
          this.$events.emit('editBookingDateEvent')
        } catch (e) {
          console.log(e)
          this.errorNotify(e, 'error', 'Brak danych', 'Brak danych do edycji rezerwacji')
        }
      } else {
        api.request(this.service, 'put', `/tasks/${this.taskId}`, this.createDataToSend())
          .then((response) => {
            this.$notify({
              type: 'success',
              text: 'Zmieniono odpowiedzialnego'
            })

            this.$emit('close')
            this.$events.emit('task:changedState')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie udało się zmienić odpowiedzialnego'
            })
          })
      }
    },
    createDataToSend () {
      let dataToSend = {
        assignee: this.model.selectedUserUuid
      }
      if (this.$isWithClients(this.service)) {
        dataToSend['main'] = true
        dataToSend['client'] = this.$route.meta.client
      }
      return dataToSend
    }
  }
}
</script>
