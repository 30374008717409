<template>
  <div class="">
    <input
      ref="input"
      v-model="value"
      :disabled="disabled"
      type="number"
      class="form-control custom-input"
      placeholder="Podaj wartość liczbową"
      style="display: inline-block"
      maxlength="10"
      @change="onBlur"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
    />
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "fieldNumberWithNull",
  mixins: [abstractField],
  methods: {
    formatValueToModel(value) {
      if (value === "") return null;
      else return value
    },
  },
};
</script>
