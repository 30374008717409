<template>
  <div
    v-if="creditPurposeData"
    class="customer-will-deliver-documentation-data-container col-xs-12"
  >
    <label
      for="customer-will-deliver-documentation-data"
      class="customer-will-deliver-documentation-label"
    >
      Dokumenty przekazane klientowi:</label
    >
    <p
      id="customer-will-deliver-documentation-data"
      v-if="creditPurposeData.customerWillDeliverDocumentation"
      class="list-group-item list-group-item-overflowed delivered"
    >
      <i class="feather-check" />
      Tak
    </p>
    <p
      id="customer-will-deliver-documentation-data"
      v-else
      class="list-group-item list-group-item-overflowed"
    >
      Nie
    </p>
  </div>
</template>

<script>
export default {
  name: "CustomerWillDeliverDocumentation",
  props: {
    creditPurposeData: {},
  },
};
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
.delivered {
  display: flex;
  align-items: center;
  color: green;
  font-weight: 600;
}
.feather-check {
  padding-right: 3px;
  margin-left:  -7px;
}
.customer-will-deliver-documentation-label {
  margin-top: 20px
}
</style>
