<template>
  <div v-if="creditPurposeData" class="col-xs-12">
    <label for="remarks-data" class="remarks-label">Uwagi banku:</label>
    <p id="remarks-data" class="list-group-item list-group-item-overflowed">
    {{ creditPurposeData.remarks ? creditPurposeData.remarks : 'b.d.' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Remarks',
  props: {
    creditPurposeData: {}
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
.remarks-label{
  margin-top: 20px
}
</style>
