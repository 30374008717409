<template>
  <div class="col-xs-12 list group">
    <label >Dane zlecającego:</label>
    <div>
      <div class="list-group-item list-group-item-overflowed ">
        <label>Adres email: </label> {{ creditPurposeData.loanAdvisor && creditPurposeData.loanAdvisor.email  ? creditPurposeData.loanAdvisor.email : ' b.d.' }} <br>
        <label>Numer telefonu: </label>{{ creditPurposeData.loanAdvisor && creditPurposeData.loanAdvisor.phone  ? creditPurposeData.loanAdvisor.phone : ' b.d.' }} <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoanAdvisor",
  props: {
    creditPurposeData: {},
  },
};
</script>

<style>
</style>