const SCHEMA_NAME_APPRAISAL = 'appraisal'
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE = 'appraisal_real_estate'
const SCHEMA_NAME_APPRAISAL_LAND_REGISTER = 'appraisal_land_register'
const SCHEMA_NAME_MAIN_VALUATION = 'main_valuation'
// new
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE_BASIC = 'appraisal_real_estate_basic'
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE_PLOT = 'appraisal_real_estate_plot'
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE_HOUSE = 'appraisal_real_estate_house'
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE_RESIDENTIAL = 'appraisal_real_estate_residential'
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE_ONLY_PARKING_SPACE = 'appraisal_real_estate_only_parking_space'
const SCHEMA_NAME_APPRAISAL_REAL_ESTATE_GARAGE = 'appraisal_real_estate_garage'

const SCHEMA_NAME_APPRAISAL_LAND_REGISTER_BASIC = 'appraisal_land_register_basic'
const SCHEMA_NAME_APPRAISAL_LAND_REGISTER_WITH_PLOT = 'appraisal_land_register_with_plot'

export { SCHEMA_NAME_APPRAISAL, SCHEMA_NAME_APPRAISAL_REAL_ESTATE, SCHEMA_NAME_APPRAISAL_LAND_REGISTER,
SCHEMA_NAME_MAIN_VALUATION, SCHEMA_NAME_APPRAISAL_REAL_ESTATE_BASIC, SCHEMA_NAME_APPRAISAL_REAL_ESTATE_PLOT,SCHEMA_NAME_APPRAISAL_REAL_ESTATE_HOUSE,
SCHEMA_NAME_APPRAISAL_REAL_ESTATE_RESIDENTIAL, SCHEMA_NAME_APPRAISAL_LAND_REGISTER_BASIC,
SCHEMA_NAME_APPRAISAL_LAND_REGISTER_WITH_PLOT, SCHEMA_NAME_APPRAISAL_REAL_ESTATE_GARAGE, SCHEMA_NAME_APPRAISAL_REAL_ESTATE_ONLY_PARKING_SPACE }
