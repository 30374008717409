<template>
  <div class="" v-if="realEstateData">
    <label>Adresy nieruchomości:</label>
    <div class="address-data-container col-xs-12 list-group-item"
         :key="`address-${addressIndex}`"
         v-for="(address, addressIndex) in realEstateData"
    >
      <label for="address-purpose-data">Zlecenie numer: {{address.appraisalTaskNumber}}</label>
      <div id="realEstate-data">
        <ul class="list-group">
          <li
            class="list-group-item"
            :key="`address-${addressIndex}-realEstateField-${realEstateKey}`"
            v-for="(realEstateValue, realEstateKey, realEstateIndex) in address.addresses"
          >
            <label for="address-purpose-data">Adres nieruchomości: {{ realEstateIndex + 1 }}</label>
            <div id="address-data">
              <ul class="list-group">
                <li
                  class="list-group-item list-group-item-overflowed"
                  :key="`address-${addressIndex}-addressField-${addressFieldIndex}`"
                  v-for="(addressField, addressFieldIndex) in filterAddressFields(realEstateValue)"
                >
                  <label for="">{{ addressFieldIndex }}:</label>
                  {{ addressField || 'b.d.' }}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import addressesMixin from '../addressesMixin.js'

export default {
name: "RealEstateAddresses",
  mixins: [addressesMixin],
  props: {
  realEstateData: {}
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
