<template>
  <div :id="'relatedUser-multiselect'">
    <form>
        <vue-multiselect
        v-model="selectedAssistant"
        v-if="categoryGroup !== 'appraisal' "
        track-by="uuid"
        id="assistant-multiselect"
        :options="this.assistantUsers"
        class="relatedUser-multiselect"
        label="fullname"
        placeholder="Asystent BWN"
        selectLabel=""
        :allow-empty="false"
        deselectLabel="Nie można odznaczyć asystenta"
        @input="updateSelectedAssistant"
      >
        <template slot="noOptions">Brak wyników</template>
      </vue-multiselect>
      <vue-multiselect
        v-if="categoryGroup === 'appraisal' "
        v-model="selectedAnalyst"
        track-by="uuid"
        id="analysts-multiselect"
        class="relatedUser-multiselect"
        label="fullname"
        :options="this.analystUsers"
        placeholder="Analityk"
        selectLabel=""
        deselectLabel="Kliknij aby odznaczyć"
        @input="updateSelectedAnalyst"
      >
        <template slot="noOptions">Brak wyników</template>
      </vue-multiselect>
      <vue-multiselect
        v-if="categoryGroup === 'appraisal' "
        v-model="selectedInspector"
        track-by="uuid"
        id="inspector-multiselect"
        class="relatedUser-multiselect"
        label="fullname"
        :options="this.inspectorUsers"
        placeholder="Inspektor"
        selectLabel=""
        deselectLabel="Kliknij aby odznaczyć"
        @input="updateSelectedInspector"
      >
        <template slot="noOptions">Brak wyników</template>
      </vue-multiselect>
      <vue-multiselect
        v-if="categoryGroup === 'appraisal' "
        v-model="selectedAppraiser"
        track-by="uuid"
        id="appraiser-multiselect"
        class="relatedUser-multiselect"
        label="fullname"
        :options="this.appraiserUsers"
        placeholder="Rzeczoznawca"
        selectLabel=""
        deselectLabel="Kliknij aby odznaczyć"
        @input="updateSelectedAppraiser"
      >
        <template slot="noOptions">Brak wyników</template>
      </vue-multiselect>
        <select-type
        v-if="categoryGroup === 'appraisal' && this.selectedAppraiser"
        name='suggestedAppraiser'
        v-validate="{ rules: { required: true }}"
        label='Rzeczoznawca sugerowany'
        v-model="suggestedAppraiser"
        :id="'suggestedAppraiser'"
        :options="options"
        :required="true"
        :error-message="vErrors.first('suggestedAppraiser')"
        data-vv-as=" 'Rzeczoznawca sugerowany' "
        :empty-first="true"
        @input="updateSuggestedAppraiser"
        />
    </form>
    <div
      class="form-group"
      :class="{ 'has-error': vErrors.has('relatedUser') }"
    >
      <label>{{ vErrors.first("relatedUser") }}</label>
    </div>
  </div>
</template>
<style scoped>
.relatedUser-multiselect {
  margin-bottom: 0.9em;
}
</style>
<script>
import Loader from "../../../../share/Loader.js";
import FormModalValidation from "../../../../share/form/FormModalValidation.js";
import VueMultiselect from "vue-multiselect";
import api from '../../../../../api'
import SelectType from '../../../../share/form/type/SelectType.vue'

const ANALYST_ROLE = "ROLE_ANALITYK"
const INSPECTOR_ROLE = "ROLE_INSPEKTOR"
const APPRAISER_ROLE = "ROLE_RZECZOZNAWCA_ZEWN"
const ASSISTANT_ROLE = "ROLE_ASYSTENT_BWN"

export default {
  components: {
    VueMultiselect,
    SelectType
  },
  mixins: [Loader, FormModalValidation],
  props: {
    show: {type: Boolean},
    value: { type: Object, default: () => {} },
    taskId: { type: Number, default: 0 },
    service: { type: String, required: true },
    categoryGroup: {type: String, required: true},
  },
  data() {
    return {
      analystUsers: [],
      inspectorUsers: [],
      appraiserUsers: [],
      assistantUsers: [],
      selectedAnalyst: null,
      selectedInspector: null,
      selectedAppraiser: null,
      selectedAssistant: null,
      suggestedAppraiser: null,
      options: [
      {label: 'Tak', value: true},
      {label: 'Nie', value: false} ]
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.getUsers()
        this.getTaskRelatedUsers()
      }
    } 
  },
  methods: {
    async getUsers() {
      try {
        let {data} = await api.request(this.service, 'get', `related-users`)
        this.analystUsers = data.find((user) => user.roleName === ANALYST_ROLE).users;
        this.inspectorUsers = data.find((user) => user.roleName === INSPECTOR_ROLE).users;
        this.appraiserUsers = data.find((user) => user.roleName === APPRAISER_ROLE).users;
        this.assistantUsers = data.find((user) => user.roleName === ASSISTANT_ROLE).users
      } catch (e) {
        console.error(e);
        this.$notify({
          type: "error",
          title: "Wystąpił błąd",
          text: "Nie można załadować ról użytkownika",
        });
      }
    },
    async getTaskRelatedUsers() {
      try {
        let {data} = await api.request(this.service, 'get', `tasks/${this.taskId}/related-users`)
        data.forEach(relatedUser => {
          switch (relatedUser.role) {
            case ANALYST_ROLE:
              this.selectedAnalyst = relatedUser
              this.value.selectedAnalyst = this.selectedAnalyst
              break
            case INSPECTOR_ROLE:
              this.selectedInspector = relatedUser
              this.value.selectedInspector = this.selectedInspector
              break
            case APPRAISER_ROLE:
              this.selectedAppraiser = relatedUser
              this.value.selectedAppraiser = this.selectedAppraiser
              this.suggestedAppraiser = relatedUser.metadata && relatedUser.metadata.suggestedAppraiser
              break
            case ASSISTANT_ROLE:
              this.selectedAssistant = relatedUser
              this.value.selectedAssistant = this.selectedAssistant
              break
          }
        })
      } catch (e) {
        console.error(e);
        this.$notify({
            type: "error",
            title: "Wystąpił błąd",
            text: "Nie można załadować osób aktualnie powiązanych.",
          });
      }
    },
    updateSelectedAnalyst(){
      this.$emit('analystUserSelected', this.selectedAnalyst)
    },
    updateSelectedInspector(){
      this.$emit('inspectorUserSelected', this.selectedInspector)
    },
    updateSelectedAppraiser(){
      this.$emit('appraiserUserSelected', this.selectedAppraiser)
    },
    updateSelectedAssistant(){
      this.$emit('assistantUserSelected', this.selectedAssistant)
    },
    updateSuggestedAppraiser() {
      this.$emit('suggestedAprraiserSelected', this.suggestedAppraiser)
    }
  },
};
</script>
