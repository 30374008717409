<template>
<div>
  <div id="appraisal-invoice-data">
    <ul class="list-group">
      <li
        class="list-group-item list-group-item-overflowed"
        v-for="(appraisalInvoiceField, appraisalInvoiceFieldIndex) in appraisalInvoice.invoices"
        :key="`main-invoice-data-${appraisalInvoiceIndex}-main-invoice-data-field-${appraisalInvoiceFieldIndex}`"
      >
        <p><label>Numer fv dla rzm: </label> {{ appraisalInvoiceField.invoiceNumber ? appraisalInvoiceField.invoiceNumber : 'b.d' }}</p>
        <p><label>Data wystawienia fv dla rzm: </label> {{ appraisalInvoiceField.date ? m$formatDate(appraisalInvoiceField.date, 'YYYY-MM-DD') : 'b.d'}}</p>
        <ul class="appraisal-invoice-data__amounts">
          <li><label>Kwota: </label> {{appraisalInvoiceField.amount ? appraisalInvoiceField.amount : 'b.d' }}</li>
        </ul>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import formatDateMixin from '../../../../../share/mixins/formatDateMixin.js'

export default {
  name: 'InvoiceAppraisal',
  props: {
    appraisalInvoice: {type: Object},
    appraisalInvoiceIndex: {type: String || Number}
  },
  mixins: [formatDateMixin],
}
</script>

<style scoped>
.appraisal-invoice-data__amounts li:first-child{
  margin-left: -25px
}
.appraisal-invoice-data__amounts li:nth-child(2), li:nth-child(3),li:nth-child(4), li:nth-child(5) {
  list-style-type: square;
}
</style>