<template>
  <div>
    <div
      class="list-group-item list-group-item-overflowed "
      :key="`creditPurposeappraisalTaskRealEstate-${creditPurposeappraisalTaskRealEstateId}`"
      v-for="(creditPurposeappraisalTaskRealEstate, creditPurposeappraisalTaskRealEstateId, creditPurposeappraisalTaskRealEstateIndex) in creditPurposeappraisalTask.realEstates">
      <div>
        <label>
        Nieruchomość: {{ creditPurposeappraisalTaskRealEstateIndex + 1 ? creditPurposeappraisalTaskRealEstateIndex + 1 : creditPurposeappraisalTaskRealEstateId + 1 }}
        </label>
      </div>
      <div>
          <div>
        <label>
          Typ: 
        </label>
        <template v-if="creditPurposeappraisalTaskRealEstate && creditPurposeappraisalTaskRealEstate.kind && creditPurposeappraisalTaskRealEstate.kind.length !== 0">
          {{ getKindLabel(creditPurposeappraisalTaskRealEstate.kind) || 'b.d' }}
        </template>
        <label v-else>b.d</label>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import realEstateKindMixin from '../realEstateKindMixin.js'

export default {
  name: 'RealEstateKinds',
  mixins: [realEstateKindMixin],
  props: {
    creditPurposeappraisalTask: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
