import moment from 'moment'

export default {
  computed: {
    taskHeaderData () {
      let data = [
        [
          [this.task.orderId ? this.task.orderId : 'b.d.', 'Numer banku', `${this.task.id}-header-orderId`],
          [this.task.state ? this.task.state.label : 'b.d.', 'Status', `${this.task.id}-header-state`],
          [this.task.author ? (this.task.author.fullname ? this.task.author.fullname : (this.task.author.label ? this.task.author.label : 'b.d.')) : 'b.d.', 'Autor', `${this.task.id}-header-author`],
          [this.task.assignee ? (this.task.assignee.fullname ? this.task.assignee.fullname : (this.task.assignee.label ? this.task.assignee.label : 'b.d.')) : 'b.d.', 'Opiekun', `${this.task.id}-header-assignee`],
        ],
        [
          [this.initialDate ? this.formatDate(this.initialDate, 'YYYY-MM-DD HH:mm') : 'b.d.', 'Zlecone', `${this.task.id}-header-createdAt`],
          [this.taskData.orderCompletionAt ?  this.formatDate(this.taskData.orderCompletionAt, 'YYYY-MM-DD HH:mm') : 'b.d.', 'Termin', `${this.task.id}-header-expectedEndDate`],
          [this.task.closedAt ? moment(this.task.closedAt).format('YYYY-MM-DD HH:mm') : 'b.d.', 'Pierwsze przekazanie', `${this.task.id}-header-closedAt`],
          [this.task.lastClosedAt ? moment(this.task.lastClosedAt).format('YYYY-MM-DD HH:mm') : 'b.d.', 'Ostatnie przekazanie', `${this.task.id}-header-lastClosedAt`]
        ]
      ]
      return this.task ? data : []
    }
  }
}
