<template>
  <div v-if="creditPurposeData" class="credit-purpose-data-container col-xs-12" >
    <label for="credit-purpose-data" class="credit-purpose-label">Cele kredytowania:</label>
    <div v-if="creditPurposeData.creditPurpose" id="credit-purpose-data">
      <ul class="list-group" v-if="creditPurposeData.creditPurpose.length !== 0">
        <li
          class="list-group-item list-group-item-overflowed"
          :key="`creditPurposeEl-${index}`"
          v-for="(creditPurposeEl, index) in creditPurposeData.creditPurpose"
        >
          {{ creditPurposeEl }}
        </li>
      </ul>
      <ul class="list-group" v-else>
        <li
          class="list-group-item list-group-item-overflowed"
        >
          Brak celi kredytowania.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditPurpose',
  props: {
    creditPurposeData: {}
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
.credit-purpose-label{
  margin-top: 20px
}
</style>
