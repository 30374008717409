<template>
  <div v-if="customerData">
    <div class="customer-data-container col-xs-12">
      <label for="customer-data">Dane klienta:</label>
      <div id="customer-data">
        <ul class="list-group">
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Adres email:</label>
            {{ customerData.email ? customerData.email : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Imię:</label>
            {{ customerData.forename ? customerData.forename : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Nazwisko:</label>
            {{ customerData.surname ? customerData.surname : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Numer telefonu komórkowy:</label>
            {{ customerData.mobilePhone ? formatPhoneField(customerData.mobilePhone) : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Numer telefonu stacjonarny:</label>
            {{ customerData.phone ? formatPhoneField(customerData.phone) : 'b.d' }}
          </li>
        </ul>

      </div>
    </div>
    <div class="customer-address-data-container col-xs-12">
      <label for="customer-address-data">Dane adresowe:</label>
      <div id="customer-address-data">
        <ul class="list-group">
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Miejscowość:</label>
            {{ customerData.contactAddress.place ? customerData.contactAddress.place : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Ulica:</label>
            {{ customerData.contactAddress.street ? customerData.contactAddress.street : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Kod pocztowy:</label>
            {{ customerData.contactAddress.postcode ? customerData.contactAddress.postcode : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Numer budynku:</label>
            {{ customerData.contactAddress.buildingNumber ? customerData.contactAddress.buildingNumber : 'b.d' }}
          </li>
          <li class="list-group-item list-group-item-overflowed">
            <label for="">Numer mieszkania:</label>
            {{ customerData.contactAddress.flatNumber ? customerData.contactAddress.flatNumber : 'b.d' }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerData',
  props: {
    customerData: {}
  },
  methods: {
    formatPhoneField (phoneNumber) {
      if (!phoneNumber) {
        return ''
      }
      if (typeof phoneNumber === 'number') {
        phoneNumber = phoneNumber.toString()
      }
      phoneNumber = phoneNumber.split(' ').join('')
      let length = phoneNumber.split('').length
      if (length === 9) {
        phoneNumber = phoneNumber.split('').map((letter, index) => {
          return index === 2 || index === 5 ? letter + ' ' : letter
        }).join('')
      }
      if (length === 11) {
        phoneNumber = phoneNumber.split('').map((letter, index) => {
          return index === 1 || index === 4 || index === 7 ? letter + ' ' : letter
        }).join('')
      }
      if (length === 12) {
        phoneNumber = phoneNumber.split('').map((letter, index) => {
          return index === 2 || index === 5 || index === 8 ? letter + ' ' : letter
        }).join('')
      }

      return phoneNumber
    }
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
