import _ from 'lodash'
import {
  DEFAULT_FALSE,
  FIELD_PROPERTY_DISABLED,
  FIELD_PROPERTY_FEATURED,
  FIELD_PROPERTY_REQUIRED,
  FIELD_PROPERTY_VISIBLE
} from '../utils/fieldPropertiesConstants.js'
import {
  prepareOnValidateByType,
  prepareValidatorByType
} from '../utils/validators.js'

export default {
  data () {
    return {
      rulesTemp: {},
      rulesCache: {},
      collections: {},
      schemaLoaded: false
    }
  },
  computed: {},
  methods: {
    prepareSchemas (rawSchemas, schemaName) {
      const schemaObject = _.cloneDeep(rawSchemas[schemaName])
      if (schemaObject === undefined && !schemaObject.hasOwnProperty('schema')) {
        return null
      }
      let schema = this.buildSchema(schemaObject)
      this.schemaLoaded = true
      return schema

    },
    buildSchema (schema) {
      const fieldMapper = (group) => group.map(field => {
        if (field.field === undefined) {
          field.field = {}
          if (field.field.settings === undefined) {
            field.field.settings = {}
          }
        }
        let obj = {...field, ...field.field, collection: field.field.settings.collection || []}
        obj.type = obj.type === 'select' ? 'customSelect' : obj.type
        obj.type = obj.type === 'collection' ? 'newCollection' : obj.type
        obj.type = obj.type === 'input' && obj.inputType === 'numberWithNull' ? 'numberWithNull' : obj.type
        if (obj.settings.multiple === true) {
          obj.help = 'Aby wybrać kilka wartości przytrzymaj klawisz CTRL'
        }
        return obj
      })
      const collectionFields = (group) => fieldMapper(group)
      const groupCollections = (group) => fieldMapper(group).filter(field => field.type === 'newCollection')
      const notSingleCollections = (group) => fieldMapper(group).filter(field => field.type !== 'newCollection' || (field.type === 'newCollection' && !field.settings.singleObject))

      schema.groups = schema.groups.map(group => {
          this.collections = {}
          const collections = groupCollections(group.fields)
          this.prepareCollections(collections, 'model', 'collection')
          this.prepareFields(collectionFields(group.fields), 'model', 'collection')
          return group
        }
      )

      schema.groups = schema.groups.map(group => {
          const collections = groupCollections(group.fields)
          return {
            // legend: group.label,
            id: group.id,
            fields: this.wrapCollectionAround(this.prepareFields(notSingleCollections(group.fields)), collections)
          }
        }
      )
      return schema
    },
    wrapCollectionAround (fields, collections) {
      //find array type collection
      const multipleCollections = collections.filter(el => !el.settings.singleObject).map(el => {
        el.schema = {
          groups: [{
            legend: '',
            fields: []
          }]
        }
        return el
      })
      // get fields that should be in array type collection
      const multipleCollFields = fields.filter(field => multipleCollections.some(col => field.collection.includes(col.model)))
      // assign proper fields to array type collection
      multipleCollFields
        .forEach(field => field.collection
              .forEach(fieldCollection =>
                multipleCollections
                  .filter(collection => collection.model === fieldCollection)
                  .forEach(collection => collection.schema.groups[0].fields.push(field))
              ))
      return fields
        // filter out fields already assigned to array type collection
        .filter(field => multipleCollections.every(col => !field.collection.includes(col.model)))
        // map fields to get collection wrapper for every field
        .map(field => {
        if (field.collection.length === 0) {
          return field
        }
        // current field's collection
        let foundCollection = collections.find(collection => field.collection.includes(collection.model))
        // prepare fields for collections
        const preparedFields = multipleCollections.map(el => el.model).includes(field.model)
          ? multipleCollections.find(el => el.model === field.model).schema.groups[0].fields
          : [field]
        // wrap field (or prepared fields) by collection
        const wrappedField = {
          ...foundCollection,
          getRules: this.getRulesProperty,
          label: '',
          type: 'newCollection',
          singleObject: foundCollection && foundCollection.settings && foundCollection.settings.singleObject || false,
          schema: {
            groups: [{
              legend: '',
              fields: [
                ...preparedFields
              ]
            }]
          }
        }
        // set field path for backend connections and applying rules etc
        let path = `${wrappedField.model}/metadata`

        // set validation listeners
        wrappedField.onValidated = prepareOnValidateByType(wrappedField)

        // set field props (mostly rules)
        this.setFieldProperties(wrappedField, path)
        return wrappedField
      })
    },
    getRulesProperty (path, property, defaultValue) {
      const handlePrefixPath = (el) => {
        return isNaN(parseInt(el)) ? el : `items/${el}`
      }
      let spath = `${this.pathPrefixIndexed.split('/').map(handlePrefixPath).join('/')}/${path}/${property}`
      spath = spath.slice(1, spath.length).split('/')

      return _.get(this.rules, spath, defaultValue)
    },
    setSpecificSettings (field) {
      let fieldParams = {}

      switch (field.type) {
        case 'input':
          fieldParams.inputType = field.inputType
          fieldParams.maxlength = field.settings.maxlength
          break
        case 'select':
          fieldParams.values = field.settings.values
          fieldParams.selectOptions = {hideNoneSelectedText: false, noneSelectedText: ' '}
          break

        case 'customSelect':
          fieldParams.values = field.settings.dynamicValues ? (model, schema) => this.getRulesProperty(field.model, 'selectValues', []) : field.settings.values
          fieldParams.selectOptions = {multiple: field.settings.multiple, hideNoneSelectedText: true}
          break

        case 'radios':
          fieldParams.values = field.settings.values
          break

        case 'textArea':
          fieldParams.max = field.settings.maxlength
          break
        case 'dateTimePicker':
          fieldParams.dateTimePickerOptions = field.settings.dateTimePickerOptions
          break
        case 'newCollection':
          fieldParams = this.prepareCollectionField(field)
          break
        case 'collection':
          fieldParams = this.prepareCollectionField(field)
          break
      }

      return fieldParams
    },
    prepareCollectionField (field) {
      try {
        return {
          ...field,
          // legend: field.label,
          label: '',
          type: 'newCollection',
          singleObject: field.settings.singleObject || false,
          schema: {
            groups: [{
              legend: '',
              fields: [...this.collections[field.model]]
            }]
          }
        }
      } catch (e) {
        console.log(field, this.collections)
        throw new Error(e)
      }

    },
    prepareCollections (collFields) {
      return collFields.forEach(fieldObject => {
        if (fieldObject.type === 'newCollection') {
          if (!this.collections.hasOwnProperty(fieldObject.model)) {
            this.collections[fieldObject.model] = []
          }
        }
      })
    },
    setFieldProperty (field, property, path, defaultValue) {
      field[property] = (model, schema) => this.getRulesProperty(path, property, defaultValue)
    },
    setFieldProperties (field, path) {
      this.setFieldProperty(field, FIELD_PROPERTY_DISABLED, path, DEFAULT_FALSE)
      this.setFieldProperty(field, FIELD_PROPERTY_REQUIRED, path, DEFAULT_FALSE)
      this.setFieldProperty(field, FIELD_PROPERTY_VISIBLE, path, DEFAULT_FALSE)
      this.setFieldProperty(field, FIELD_PROPERTY_FEATURED, path, DEFAULT_FALSE)
    },
    prepareFields (fields) {
      return fields.map(fieldObject => {
        let path = `${fieldObject.model}`

        if (!fieldObject.hasOwnProperty('collection') || (fieldObject.hasOwnProperty('collection') && fieldObject.collection && fieldObject.collection.length === 0)) {
          this.setFieldProperties(fieldObject, path)
          if (fieldObject.type !== 'newCollection' && fieldObject.type !== 'collection') {
            fieldObject.validator = prepareValidatorByType(fieldObject)
          }
        }
        if (fieldObject.type === 'newCollection' || fieldObject.type === 'collection') {
          path = `${fieldObject.model}/metadata`
          this.setFieldProperties(fieldObject, path)
        }
        fieldObject.inputName = fieldObject.label
        fieldObject.onValidated = prepareOnValidateByType(fieldObject)
        fieldObject.getRules = this.getRulesProperty

        fieldObject = {...fieldObject, ...this.setSpecificSettings(fieldObject)}
        if (fieldObject.settings.hasOwnProperty('collection')) {
          let collections = this.collections
          if (collections.hasOwnProperty(fieldObject.settings.collection)) {
            collections[fieldObject.settings.collection].push(fieldObject)
          } else {
            collections[fieldObject.settings.collection] = [fieldObject]
          }
          this.collections = collections
        }
        return fieldObject
      })
    }
  }
}
