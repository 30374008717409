<template>
  <div v-if="collectionLoaded && schema && schema.visible()">
    <div v-if="!localSchema.singleObject">
      <template
        v-for="(modelItem, index) in collection"
      >
        <div
          :key="`${localSchema.model}-${index}`"
          class="field-new-collection-item-array"
        >
          <vue-form-generator
            :ref="`array-form`"
            :schema="collectionArraySchema[index]"
            :model="modelItem"
            :form-id="`${formId}-${localSchema.model}-${index}-${collectionFormOptions(index).path}`"
            :options="collectionFormOptions(index, modelItem)"
            @model-updated="(...e) => modelUpdated(...e, index)"
            @collection-updated="(...e) => submitCollectionField(...e, index)"
            @collection-new-updated="(e) => submitNewCollectionField({colObject: e, index})"
            @validated="(...args) => handleArrayValidated(...args, index)"
          />
          <button
            v-if="model.kind !== 'Residential' && !localSchema.required() ? model[schema.model].length >= 2 : model[schema.model].length >= 1"
            type="button"
            class="button-delete"
            :disabled="isPending"
            @click="() => remove(index)"
          />
        </div>
      </template>
    </div>
    <div v-else>
      <template>
        <div
          v-if="localSchema.schema.groups[0].fields"
          class="field-new-collection-item-object"
        >
          <vue-form-generator
            :ref="`object-form`"
            :schema="collectionArraySchema"
            :model="collection"
            :form-id="`${formId}-${localSchema.model}-${collectionFormOptions(0).path}`"
            :options="collectionFormOptions(0)"
            @model-updated="(...e) => modelUpdated(...e, 0)"
            @collection-updated="(...e) => submitCollectionField(...e, 0)"
            @collection-new-updated="(...e) => submitNewCollectionField(...e)"
            @validated="handleObjectValidated"
          />
        </div>
      </template>
    </div>
    <div v-if="!localSchema.singleObject && !schema.settings.addButtonDisabled" class="button-add-wrapper">
      <div
        type="button"
        class="button-add"
        @click="add"
      />
      <span class="button-add-label">{{this.schema.settings.addButtonLabel ? this.schema.settings.addButtonLabel : 'Dodaj'}}</span>
    </div>
  </div>
</template>

<script>
import {abstractField} from 'vue-form-generator'
import _ from 'lodash'
import { mapGetters } from "vuex";
import {
  FORM_ADD,
  FORM_REMOVE,
  FORM_REPLACE
} from '../utils/jsonPatchContsants.js'
import {
  prepareOnValidateByType,
  prepareValidatorByType
} from '../utils/validators.js'
import {DEFAULT_FALSE} from '@/components/bundles/intbwn/share/form/utils/fieldPropertiesConstants.js'

export default {
  props: ['formId'],
  mixins: [abstractField],
  data () {
    return {
      collectionLoaded: false,
      baseFields: null,
      localSchema: null,
      collectionArraySchema: null
    }
  },
  computed: {
    ...mapGetters(['isPending']),
    collection () {
      return this.model[this.schema.model] || {}
    }
  },
  watch: {
    model: {
      deep: true,
      handler: function (newVal, prevVal) {
        if (!this.model[this.schema.model] && this.localSchema.singleObject) {
          this.model[this.schema.model] = {}
        }
      }
    },
    collection: {
      handler: function (newVal, prevVal) {
        if (!this.localSchema.singleObject && newVal.length > prevVal.length) {
          this.prepareCollectionSchemas()
        }
      }
    }
  },
  created () {
    this.prepareLocalSchema()
    this.prepareCollectionSchemas()
  },
  mounted () {
  },
  methods: {
    handleObjectValidated (isValid, errors, ref) {
      this.schema.validator = () => {
        if (!isValid) {
          return errors.map(error => error.error)
        }
        return []
      }
      if (this.collectionFormOptions(0).validateAfterChanged) {
        this.validate()
      }
    },
    handleArrayValidated (isValid, errors, ref, index) {
      let allErrors = []
      this.$refs[`array-form`].forEach(arrayForm => arrayForm.errors.forEach(error => allErrors.push(error.error)))
      this.schema.validator = () => {
        if (allErrors.length > 0) {
          return allErrors
        }
        return []
      }

      if (this.collectionFormOptions(index).validateAfterChanged) {
        this.validate()
      }
    },
    prepareCollectionSchemas () {
      if (!this.localSchema.singleObject) {
        if (this.collection !== null && this.collection.hasOwnProperty('length')) {
          this.collectionArraySchema = this.collection.map((modelItem, modelIndex) => {
            return this.createCollectionArraySchema(this.localSchema, modelIndex)
          })
        }

        return
      }
      this.collectionArraySchema = this.createCollectionArraySchema(this.localSchema, 0)
    },
    prepareLocalSchema () {
      let schema = _.cloneDeep(this.schema)
      schema.collection = true
      schema.collectionName = schema.model
      const prepareFields = () => schema.schema.groups[0].fields.map(f => {
        let tempField = f
        tempField.getRules = f.getRules
        tempField.collectionIndex = 0
        return tempField
      })

      if (!this.baseFields) {
        this.baseFields = _.cloneDeep(prepareFields())
      }

      schema.schema.groups[0] = {fields: prepareFields(), legend: ''}
      this.localSchema = schema
      this.collectionLoaded = true
    },
    collectionFormOptions (index, modelItem) {
      let formOptions = _.cloneDeep(this.formOptions)
      return Object.assign(formOptions, {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: false,
        'collection': true,
        newCollectionType: true,
        path: [...(this.formOptions.path ? this.formOptions.path : []), this.schema.singleObject ? this.schema.model : `${this.schema.model}/${modelItem ? modelItem.id : this.model.id}`],
        indexedPath: [...(this.formOptions.indexedPath ? this.formOptions.indexedPath : []), this.schema.singleObject ? this.schema.model : `${this.schema.model}/${index}`],
        rulesPath: [...(this.formOptions.rulesPath ? this.formOptions.rulesPath : []), this.schema.singleObject ? `${this.schema.model}/metadata` : `${this.schema.model}/items/${index}`]
      })
    },
    add () {
      this.$emit('collection-new-updated', {
        value: null,
        op: FORM_ADD,
        path: '/' + this.schema.model + '/-',
        indexedPath: '/' + this.schema.model
      })
    },
    remove (index) {
      let emmittedObj = {
        value: null,
        op: FORM_REMOVE,
        path: '/' + this.schema.model + '/' + this.model[this.schema.model][index].id,
        indexedPath: '/' + this.schema.model + '/' + index
      }

      this.$emit('collection-new-updated', emmittedObj)
      this.$emit('model-updated', this.model[this.schema.model], this.schema.model, this.collectionFormOptions(index))

    },
    modelUpdated (...args) {
      let path = this.schema.model + '/' + args[1]
      this.$emit('model-updated', args[0], path, args[2])
    },
    submitCollectionField (collectionNewVal, schema, target, shouldRemove = false, index) {
      if (shouldRemove) {
        this.modelUpdated(collectionNewVal, schema, index)
      }

      this.$emit('collection-updated', collectionNewVal, schema, target, shouldRemove)
    },
    submitNewCollectionField (args) {
      if (args.hasOwnProperty('colObject') && args.hasOwnProperty('index')) {
        args.value = args.colObject.model[args.colObject.schema.model]
        args.op = FORM_REPLACE
        args.path = '/' + args.colObject.formOptions.path.join('/') +  '/' + args.colObject.schema.model
        args.indexedPath = '/' + args.colObject.formOptions.indexedPath.join('/') + '/' + args.colObject.schema.model
        this.$emit('collection-new-updated', args)

        return
      }
      args.value = args.model[args.schema.model]
      args.op = FORM_REPLACE
      args.path = '/' + args.formOptions.path.join('/') + '/' + args.schema.model
      args.indexedPath = '/' + args.formOptions.path.join('/') + '/' + args.schema.model

      this.$emit('collection-new-updated', args)
      this.validate()

    },
    setFieldRules (field, modelIndex, path) {
      field.disabled = (model, schema) => field.getRules(path, 'disabled', DEFAULT_FALSE) || field.getRules(path, 'readonly', DEFAULT_FALSE)
      field.required = (model, schema) => field.getRules(path, 'required', DEFAULT_FALSE)
      field.visible = (model) => field.getRules(path, 'visible', DEFAULT_FALSE)
      field.featured = (model) => field.getRules(path, 'featured', DEFAULT_FALSE)
      field.validator = prepareValidatorByType(field)
      field.onValidated = prepareOnValidateByType(field)
    },
    getPathForFieldRules (field, collectionObject, modelIndex) {
      if (collectionObject) {
        return [...this.collectionFormOptions(modelIndex).rulesPath, field.model].join('/').split('/metadata').join('')
      }
      return [...this.collectionFormOptions(modelIndex).rulesPath, field.model].join('/')
    },
    prepareFieldRules (schema, fields, modelIndex) {
      if (schema.hasOwnProperty('getRules')) {
        fields.forEach(field => {
            let path = this.getPathForFieldRules(field, schema.singleObject, modelIndex)

            field.rulesPath = path
            field.modelPath = path.split('items/').join('')

            if (field.type === 'newCollection' || field.type === 'collection') {
              field.rulesPath = path + '/metadata'
              path = path + '/metadata'
            }
            this.setFieldRules(field, modelIndex, path)
          }
        )
      }
    },
    createCollectionArraySchema (schema, modelIndex) {
      schema = _.cloneDeep(schema)

      if (schema.hasOwnProperty('getRules')) {
        this.prepareFieldRules(schema, schema.schema.groups[0].fields, modelIndex)
      }

      if (!schema.singleObject) {
        return {
          groups: [schema.schema.groups[0]]
        }
      }
      return {
        groups: [schema.schema.groups[0]]
      }
    },
    formatValueToModel (value) {
      return value
    }
  }
}

</script>
<style scoped lang='scss'>
.button-delete {
  position: relative;
  width: 21px;
  height: 21px;
  background-color: $errorColor;
  top: -10%;
  left: -1rem;
  z-index: 3;
  border-radius: 50%;
  border: 1px solid rgba(66, 165, 246, 0.2);
  cursor: pointer;
  -webkit-transition: .5s all ease;
  transition: .5s all ease;
}

.button-add-wrapper {
  margin-bottom: 10px;
}

.button-add {
  position: relative;
  width: 21px;
  height: 21px;
  background-color: $successColor;
  top: 1.95rem;
  left: -1rem;
  z-index: 3;
  border-radius: 50%;
  border: 1px solid rgba(66, 165, 246, 0.2);
  cursor: pointer;
  -webkit-transition: .5s all ease;
  transition: .5s all ease;
}

.field-new-collection-item-array {
  border: 1px solid rgba(66, 165, 246, 0.2);
}

.button-delete:before, .button-delete:after, .button-add:before, .button-add:after {
  content: '';
  display: block;
  width: 12px;
  height: 1px;
  background-color: #242a3c;
  position: absolute;
  top: 9px;
  left: 3.5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-delete:after, .button-add:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.button-delete:hover, .button-add:hover {
  background-color: #fff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.button-delete:disabled {
  background-color: transparent;
}

.button-add {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button-add:hover {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.button-add-label {
  border: 1px solid rgba(66, 165, 246, 0.2);
  border-radius: 4px;
  padding: 10px 20px;
}
</style>
