<template>
  <div>
    <BaseConfirmModal
      :show="show"
      subject="cloneTask"
      :title="title"
      :ok-text="okText"
      :cancel-text="cancelText"
      @close="$emit('close')"
      @confirm="submitForm"
    />
    <blocking-loader :show="loading">
      {{ loaderMessage }}
    </blocking-loader>
  </div>
</template>

<script>
import api from '../../../../../api'
import BaseConfirmModal from '../../../../share/modal/BaseConfirmModal.vue'
import BlockingLoader from '../../../../share/BlockingLoader.vue'

export default {
  components: {
    BaseConfirmModal,
    BlockingLoader
  },
  props: {
    appraisalTaskId: { default () { return null } },
    service: { type: String, required: true },
    show: { type: Boolean, required: true },
    taskId: { required: true },
    taskType: { type: String, required: true },
    workflowName: { type: String, required: true },
    client: {type: String, required: true}
  },
  data () {
    return {
      title: 'Czy chcesz skopiować zlecenie?',
      loaderMessage: 'Trwa zapisywanie. Proszę czekać.',
      loading: false,
      okText: 'Tak',
      cancelText: 'Nie'
    }
  },
  methods: {
    submitToServiceWithClients () {
      api.request(this.service, 'post', this.getCopyUrl(), { sourceId: this.appraisalTaskId ? this.appraisalTaskId : this.taskId, client: parseInt(this.client) })
        .then((response) => {
          this.$notify({
            type: 'success',
            text: 'Zlecenie skopiowane'
          })
          let location = response.headers.location.split('/')

          this.$emit('close')
          this.toggleLoader()

          this.redirect(location[location.length - 1])
        })
        .catch((error) => {
          this.toggleLoader()
          this.errorNotify(error, error.response.data.message)
        })
    },
    submitToBaseService () {
      api.request(this.service, 'post', this.getCopyUrl())
        .then((response) => {
          this.$notify({
            type: 'success',
            text: 'Zlecenie skopiowane'
          })

          this.$emit('close')
          this.toggleLoader()

          this.redirect(response.data.id)
        })
        .catch((error) => {
          this.toggleLoader()
          this.errorNotify(error, error.response.data.message)
        })
    },
    submitForm () {
      this.toggleLoader()
      this.$isWithClients(this.service) ? this.submitToServiceWithClients() : this.submitToBaseService()
    },
    getCopyUrl () {
      if (this.$isWithClients(this.service)) {
        return '/tasks'
      } else {
        if (this.appraisalTaskId) {
          return `/tasks/${this.taskId}/${this.taskType}/${this.appraisalTaskId}/copy`
        }

        return `/tasks/${this.taskType}/${this.taskId}/copy`
      }
    },
    redirect (id) {
      if (this.$isWithClients(this.service)) {
        if (this.appraisalTaskId) {
          this.$router.push({ name: `${this.service}_appraisal_task_details`, params: { id: this.taskId, appraisalTaskId: id } })
        } else {
          this.$router.push({ name: `${this.service}_task_details`, params: { id: id } })
        }
      } else {
        if (this.appraisalTaskId) {
          this.$router.push({ name: `intgen_${this.workflowName}_appraisal_task_details`, params: { id: this.taskId, appraisalTaskId: id } })
        } else {
          this.$router.push({ name: `intgen_${this.workflowName}_task_details`, params: { id: id } })
        }
      }
    },
    toggleLoader () {
      this.loading = !this.loading
    }
  }
}
</script>
