<template>
  <div v-if="creditPurposeData" class="customer-will-deliver-documentation-data-container col-xs-12">
    <label for="credit-purpose-data-task-type">Typy zleceń:</label>
    <div id="credit-purpose-data-task-type" v-if="creditPurposeData.taskTypes">
      <div class="list-group" v-if="creditPurposeData.taskTypes.length !== 0">
        <div
          class="list-group-item list-group"
          :key="`creditPurposeappraisalTask-${appraisalTaskId}`"
          v-for="(creditPurposeappraisalTask, appraisalTaskId) in creditPurposeData.taskTypes"
        >
          <label>Zlecenie numer: {{ creditPurposeappraisalTask.appraisalTaskNumber }}</label>
          <RealEstateKinds :credit-purposeappraisal-task="creditPurposeappraisalTask"></RealEstateKinds>
        </div>
      </div>
      <ul class="list-group" v-else>
        <li
          class="list-group-item list-group-item-overflowed"
        >
          Brak podzleceń.
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import RealEstateKinds from './RealEstateKinds.vue'
export default {
  name: 'RealEstateKindsAppraisals',
  components: {RealEstateKinds},
  props: {
    creditPurposeData: {}
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
