<template>
  <div
    :id="'assignee-multiselect'"
  >
    <form>
      <vue-multiselect
        :id="'input-assignee-multiselect'"
        v-validate="'required'"
        class="assignee-multiselect"
        :name="'assignee'"
        :value="multiselectValue"
        :label="(service === 'extgen' || service === 'extavi' || service === 'extpko' || service === 'extgsl' || service === 'extemn' || service === 'extacn' || service === 'extgcz' || service === 'extshp') ? 'fullname' : 'label'"
        :select-label="'Wybierz'"
        :selected-label="'Wybrano'"
        :deselect-label="'Odznacz'"
        :options="(service === 'extgen' || service === 'extavi' || service === 'extpko' || service === 'extgsl' || service === 'extemn' || service === 'extacn' || service === 'extgcz' || service === 'extshp') ? appraisers : possibleUsers"
        data-vv-as="'Osoba odpowiedzialna'"
        :placeholder="'Wybierz/Szukaj'"
        @input="handleMultiselectInput"
      >
        <template slot="noResult">
          <p>Brak wyników</p>
        </template>
        <template slot="noOptions">
          <p>Brak wyników</p>
        </template>
      </vue-multiselect>
      <div v-if="possibleUsers.length === 0 && appraisers.length === 0">
        <label>Brak odpowiedzialnych do przypisania</label>
      </div>
    </form>
    <div
      class="form-group"
      :class="{'has-error': vErrors.has('assignee') }"
    >
      <label>{{ vErrors.first('assignee') }}</label>
    </div>
  </div>
</template>

<script>
import Loader from '../../../../../share/Loader.js'
import FormModalValidation from '../../../../../share/form/FormModalValidation.js'
import api from '../../../../../../api'
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    VueMultiselect,
  },
  mixins: [
    Loader,
    FormModalValidation
  ],
  props: {
    open: { type: Boolean, required: true, default: false },
    assigneeUuid: { type: String, required: true, default: '' },
    value: { type: Object, default: () => {} },
    taskId: { type: Number, default: 0 },
    service: { type: String, required: true },
    task: {type: Object, default: () => {}}
  },
  data () {
    return {
      possibleUsers: [],
      multiselectValue: null,
      appraisers: []
    }
  },
  watch: {
    open (visible) {
      if (visible) {
        this.loadAssignees()
      }
    }
  },
  methods: {
    handleMultiselectInput (event) {
      let services = ['extgen', 'extpko', 'extavi', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp']
      if (services.indexOf(this.service) > -1) {
        this.multiselectValue = event
        this.$emit('multiselectInput', event)
      } else {
        if (event && event.value) {
          this.multiselectValue = event
          this.$emit('multiselectInput', event.value)
        } else {
          this.multiselectValue = ''
          this.$emit('multiselectInput', '')
        }
      }
    },
    loadAssignees () {
      if (this.possibleUsers.length > 0) {
        return
      }

      this.toggleLoading()
      let services = ['extgen', 'extpko', 'extavi', 'extgsl', 'extemn', 'extacn', 'extgcz', 'extshp']
      if (services.indexOf(this.service) > -1) {
        this.getExtAssignees(this.service)
      } else {
        this.getAssignees()
      }
    },
    getAssignees () {
      api.request(this.service, 'get', `/users/${this.assigneeUuid}/roles`)
        .then((response) => {
          let userRole = response.data.filter(role => role.customized === true || role.businessRole === true)[0].name

          api.request(this.service, 'get', `/roles/${userRole}/users?format=selectOptions`)
            .then((response) => {
              for (let user of response.data) {
                if (user.id === this.assigneeUuid) {
                  continue
                }
                this.possibleUsers.push({ label: user.label, value: user.uuid })
              }
              this.possibleUsers.sort((a, b) => a.label.split(' ')[1].localeCompare(b.label.split(' ')[1]))
            })
            .catch((e) => {
              console.error(e)
              this.$notify({
                type: 'error',
                title: 'Wystąpił błąd',
                text: 'Nie można załadować użytkowników roli biznesowej'
              })
            })
        })
        .catch((e) => {
        console.error(e)
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról użytkownika'
          })
        })
    },
    async getExtAssignees (serviceName) {
      let bookingDateResponse = await api.request(serviceName, 'get', `/tasks/main-vehicle/${this.taskId}/booking-date`)
      let bookingDate = bookingDateResponse.data
      try {
        let response = await api.request(serviceName, 'get', `/booking-dates/${bookingDate.id}/free-employees`)

        this.appraisers = Object.values(response.data)[0].map(appraiser => {
          return {
            businessHourId: appraiser.parentBusinessHour.id,
            fullname: appraiser.employee.fullname,
            startAt: appraiser.startAt,
            endAt: appraiser.endAt,
            bookingDateToChange: bookingDate.id
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
