<template>
 <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
        v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_customer'}) && customerData">
      <CustomerData :customer-data="customerData"></CustomerData>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_credit_purpose'}) && creditPurposeData">
      <RelatedUsersMain  :related-users-data="relatedUsersData"  :service="this.service"/>
      <LoanAdvisor  :credit-purpose-data="creditPurposeData"/>
      <Remarks :credit-purpose-data="creditPurposeData" />
      <CustomerWillDeliverDocumentation  :credit-purpose-data="creditPurposeData" />
      <CreditPurpose :credit-purpose-data="creditPurposeData" />
      <RealEstateKindsAppraisals :credit-purpose-data="creditPurposeData"/>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_real_estate'}) && realEstateData">
      <RealEstateAddresses :real-estate-data="realEstateData"></RealEstateAddresses>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" v-if="$auth.isAllowed({service: this.service, action: ['get_summary_main_invoice_main', 'get_summary_main_invoice_appraisal'], allowOneOfActions: true})">
      <InvoicesMain v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_invoice_main'}) && mainInvoicesData.length !== 0" :main-invoices-data="mainInvoicesData"/>
      <InvoicesAppraisal v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_invoice_appraisal'}) && appraisalsInvoicesData.length !== 0" :appraisal-invoices-data="appraisalsInvoicesData" />
    </div>
  </div>
</template>

<script>
import ErrorNotify from '@/components/share/mixins/ErrorNotify.js'
import CustomerWillDeliverDocumentation from './components/CustomerWillDeliverDocumentation.vue'
import Remarks from './components/Remarks.vue'
import LoanAdvisor from './components/LoanAdvisor.vue'
import CreditPurpose from './components/CreditPurpose.vue'
import CustomerData from './components/CustomerData.vue'
import RealEstateAddresses from './components/RealEstateAddresses.vue'
import InvoicesMain from './components/InvoicesMain.vue'
import InvoicesAppraisal from './components/InvoicesAppraisal.vue'
import summaryRequests from './summaryRequests.js'
import RealEstateKindsAppraisals
  from './components/RealEstateKindsAppraisals.vue'
  import RelatedUsersMain from './components/RelatedUsersMain.vue'

export default {
  name: 'SummaryMain',
  components: {
    RealEstateKindsAppraisals,
    InvoicesAppraisal,
    InvoicesMain,
    RealEstateAddresses,
    CustomerData,
    CreditPurpose,
    CustomerWillDeliverDocumentation,
    RelatedUsersMain,
    Remarks,
    LoanAdvisor
  },
  props: {
    taskId: {default: () => null},
    taskGroup: {},
    service: {type: String, default: () => ''},
    summaryTabOpened: {type: Boolean},
    dataToShow: {type: Array},
  },
  mixins: [ErrorNotify, summaryRequests],
  watch: {
    summaryTabOpened: function (newValue, value) {
      if (newValue) {
        this.loadData()
      }
    }
  },
  data () {
    return {
      customerData: null,
      creditPurposeData: null,
      realEstateData: null,
      mainInvoicesData: [],
      appraisalsInvoicesData: [],
      relatedUsersData: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_customer'
      }, () => this.getCustomerData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_credit_purpose'
      }, () => this.getCreditPurposeData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_real_estate'
      }, () => this.getRealEstateData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_main'
      }, this.getMainInvoicesData)

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_appraisal'
      }, this.getAppraisalsInvoicesData)

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_task_related_users'
      }, () => this.getRelatedUsersData(this.taskGroup))
    },
    handleInvoicesUpdated () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_main'
      }, this.getMainInvoicesData)

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_main_invoice_appraisal'
      }, this.getAppraisalsInvoicesData)
    }
  }
}
</script>
<style scoped>

</style>
