<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="(bool) => (beforeSubmitTrigger = bool)"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
      :task-id="taskId"
      :service="service"
      @analystUserSelected="updateSelectedAnalyst"
      @inspectorUserSelected="updateSelectedInspector"
      @appraiserUserSelected="updateSelectedAppraiser"
      @assistantUserSelected="updateSelectedAssistant"
      @suggestedAprraiserSelected="updateSuggestedAppraiser"
      :show="show"
      :categoryGroup="categoryGroup"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from "../../../../share/modal/BaseFormModal.vue";
import FormModalMixin from "../../../../share/modal/FormModalMixin.js";
import FormModal from "../relatedUsers/RelatedUsersForm";
import api from "../../../../../api";

export default {
  components: {
    FormModal,
    BaseFormModal,
  },
  mixins: [FormModalMixin],
  props: {
    taskId: { required: true },
    categoryGroup: {type: String, required: true},
  },
  data() {
    return {
      model: {},
      title: "Osoby powiązane",
      okText: "Zapisz",
    };
  },
  methods: {
    submitForm() {
      let users = this.categoryGroup === 'appraisal' ? [
        this.model.selectedAnalyst,
        this.model.selectedInspector,
        this.model.selectedAppraiser,
      ] : [
        this.model.selectedAssistant,
      ]
      users = users.filter((user) => user != null);
      api
        .request(
          this.service,
          "post",
          `/tasks/${this.taskId}/related-users`,
          users
        )
        .then((response) => {
          this.toggleLoading();
          this.$emit("close");
          this.$events.emit("task:changedState");

          this.$notify({
            type: "success",
            text: "Dodano osoby powiązane z zadaniem",
          });
        })
        .catch((error) => {
          this.toggleLoading();
          this.errorNotify(error, "error", "Wystąpił błąd.");
        });
    },
    updateSelectedAnalyst(selectedAnalyst) {
      this.model.selectedAnalyst = selectedAnalyst;
    },
    updateSelectedInspector(selectedInspector) {
      this.model.selectedInspector = selectedInspector;
    },
    updateSelectedAppraiser(selectedAppraiser) {
      this.model.selectedAppraiser = selectedAppraiser;

    },
    updateSelectedAssistant(selectedAssistant) {
      this.model.selectedAssistant = selectedAssistant;
    },
    updateSuggestedAppraiser(suggestedAppraiser) {
      this.model.selectedAppraiser.metadata = {suggestedAppraiser: suggestedAppraiser}
    }
  },
};
</script>
